import "./styles/index.scss";
import styles from "./App.module.scss";
import React from "react";
import { Route, Routes } from "react-router-dom";
import "projects/common/shared/lib/yup";
import Header from "./header/Header";
import AuthRoute from "../../common/app/auth-route/AuthRoute";
import AppRoutes from "../../common/pages/AppRoutes";
import Analytics from "../../common/app/analytics/Analytics";
import LoginModule from "../pages/login";

const App: React.FC<any> = () => {
  return (
    <>
      <div className={styles.app}>
        <Routes>
          <Route path="/login/*" key="login" element={<LoginModule />} />
          <Route
            path="*"
            element={
              <AuthRoute>
                <header className={styles.header}>
                  <Header />
                </header>
                <main className={styles.main}>
                  <AppRoutes />
                </main>
              </AuthRoute>
            }
          />
        </Routes>
      </div>
      <Analytics />
    </>
  );
};

export default App;
