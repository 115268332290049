import React, { PropsWithChildren } from "react";
import styles from "./Header.module.scss";

import MainMenu from "../../../common/app/header/main-menu/MainMenu";
import User from "../../../common/features/user/User";
import Logo from "../logo/Logo";

interface OwnProps {}

type Props = OwnProps;

const Header = (props: PropsWithChildren<Props>) => {
  return (
    <div className={styles.header}>
      <div className={styles.headerLeft}>
        <div className={styles.logo}>
          <Logo id="header-logo" />
        </div>
        <nav className={styles.nav}>
          <MainMenu />
        </nav>
      </div>
      <div className={styles.user}>
        <User />
      </div>
    </div>
  );
};

export default Header;
